import styled, { css } from "styled-components";

const isOpenSection = ({ isOpen }) => {
  if (isOpen) {
    return css`
      overflow: visible;
      opacity: 1;
    `;
  }

  return css`
    opacity: 0;
    overflow: hidden;
  `;
};

export const Section = styled.section`
  font-size: 14px;
  color: ${(p) => p.theme.colors.secondary};

  ${isOpenSection};
  transition: height 0.3s, opacity 0.3s;
  will-change: height, opacity;

  div {
    padding: 0 15px 15px 15px;
  }

  p {
    line-height: 22px;
  }

  p + p {
    margin-top: 15px;
  }
`;

const isActive = ({ isOpen, theme }) => {
  if (isOpen) {
    return css`
      color: ${theme.colors.tertiary};
    `;
  }
};

export const Header = styled.div`
  cursor: pointer;
  color: ${(p) => p.theme.colors.secondary};
  font-size: 16px;
  line-height: 25px;
  font-weight: 700;
  padding: 15px;
  border-top: 1px solid #d3dceb;
  display: flex;
  justify-content: space-between;
  transition: color 0.2s;

  svg {
    font-size: 25px;
    flex-shrink: 0;
    margin-left: 10px;
  }

  h3 {
    font-size: 16px;
  }

  ${isActive};
`;
