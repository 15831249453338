import styled from "styled-components";
import MainSection from "components/MainSection";
import { ColContent, ColImage } from "components/MainSection/styles";

export const CustomSection = styled(MainSection)`
  ${ColContent} {
    width: 50%;
  }

  ${ColImage} {
    width: 50%;
  }
`;

export const List = styled.ul`
  display: flex;
  list-style: none;

  li:not(:first-child) {
    margin-left: 15px;
  }
`;
