import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

export default function Leads({
  title = "Captar leads",
  text = `Ative uma pop-up que será mostrada enquanto seu cliente vê as imagens dos imóveis, pedindo seu nome, telefone e e-mail para continuar vendo as próximas fotos.`
}) {
  return (
    <MainSection
      reversed={true}
      title={title}
      text={text}
      image={<Image src="/inicio/capte-leads.svg" alt={title} />}
    />
  );
}
