import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function RecurringEmail({ title, text }) {
  return (
    <MainSection
      reversed={true}
      id="agenda"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Mantenha seu estoque atualizado.",
        "Comunicação ativa com seu cliente.",
      ]}
    />
  );
}

RecurringEmail.defaultProps = {
  title: "E-mail recorrente para o proprietário",
  text: `Ative e-mails mensais para proprietários e mantenha seu estoque e preço ofertado sempre atualizado.`,
};

RecurringEmail.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default RecurringEmail;
