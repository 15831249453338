import styled from "styled-components";
import Button from "components/Button";
import { Wrapper as WrapperButton } from "components/Button/styles";

export const Wrapper = styled.div`
  width: 100%;
  padding: 25px;
  max-width: 1380px;
  margin: 0 auto;
`;
export const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #a1d1fb;
  border-radius: 10px;
  padding: 50px 50px 50px;

  @media all and (max-width: 1024px) {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  p {
    font-size: 14px;
  }
`;
export const Title = styled.h2`
  font-size: 35px;
  margin-bottom: 10px;

  @media all and (max-width: 1024px) {
    font-size: 24px;
  }
`;
export const TextContent = styled.div``;
export const Action = styled.div``;
export const WrapButton = styled.div`
  position: relative;

  ${WrapperButton} {
    position: relative;
  }

  @media all and (min-width: 1367px) {
    ${WrapperButton} + ${WrapperButton} {
      margin-left: 15px;
    }
  }

  @media all and (max-width: 1366px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    ${WrapperButton} + ${WrapperButton} {
      margin-top: 15px;
    }
  }

  img {
    position: absolute;
    top: 92%;
    right: 80%;
    pointer-events: none;
  }
`;

export const CustomButton = styled(Button).attrs(() => ({
  as: "a",
}))`
  background: #c9e6fc;
  color: #0084f4;
  font-weight: 600;
`;
