import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function VisitForm({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="agenda"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Aprimore ainda mais o seu atendimento.",
        "Transmita mais profissionalismo aos seus clientes.",
      ]}
    />
  );
}

VisitForm.defaultProps = {
  title: "Ficha de visita",
  text: `Antes de visitar o imóvel com o cliente, gere automaticamente uma ficha de visita com todos os dados dele e também do imóvel.`,
};

VisitForm.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default VisitForm;
