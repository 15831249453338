import qrcode from "qrcode";
import { useEffect, useState } from "react";

export const useQrcode = (
  text,
  options = {
    width: 300,
    color: {
      dark: "#000000",
      light: "#ffffff",
    },
    margin: 0,
    border: 1,
  }
) => {
  const [imageUrl, setImageUrl] = useState("");

  async function getImage() {
    const url = await qrcode.toDataURL(text, options);
    setImageUrl(url);
  }

  useEffect(() => {
    getImage();
  }, [text]);

  return imageUrl;
};
