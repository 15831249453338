import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function ChatGpt({ title, text }) {
  return (
    <MainSection
      reversed
      id="ChatGpt"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Inteligência artificial.",
        "Anúncios imobiliários atrativos e cativantes.",
        "Conquiste vantagem competitiva no mercado.",
      ]}
    />
  );
}

ChatGpt.defaultProps = {
  title: "Diferencie-se com Descrições de Imóveis Cativantes",
  text: `Vá além das descrições comuns. Usamos inteligência artificial para te ajudar a criar descrição de imóveis, destacando detalhes exclusivos e estimulando a imaginação dos clientes.`,
};

ChatGpt.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default ChatGpt;
