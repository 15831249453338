import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function Metrics({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="metrics"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Otimize a experiência do usuário",
        "Saiba as melhores fontes de tráfego do seu site",
      ]}
    />
  );
}

Metrics.defaultProps = {
  title: "Análise Abrangente de Acesso ao Seu Site",
  text: `Descubra informações essenciais sobre a performance do seu site, incluindo o número de visitantes, páginas visualizadas, tempo médio de navegação, e valiosamente, a origem do tráfego e os tipos de dispositivos utilizados. Esses dados proporcionam insights para otimizar a experiência do usuário e ajudam a direcionar estrategicamente seus esforços para valorizar sua melhores fontes de tráfego.`,
};

Metrics.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Metrics;
