import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function OruloDwv({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="orulo-dwv"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Atualização automática e informações completas dos empreendimentos importados.",
        "Estoque de imóveis ampliado.",
        "Maior potencial de vendas.",
      ]}
    />
  );
}

OruloDwv.defaultProps = {
  title: "Integração com Órulo e DWV",
  text: `Tenha acesso a milhares de imóveis de construtoras e incorporadoras de forma automática, proporcionando uma variedade de opções para clientes. Com um estoque ampliado, você estará posicionado para explorar um maior potencial de vendas e uma melhor competitividade no mercado imobiliário.`,
};

OruloDwv.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default OruloDwv;
