import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function SellAuthorization({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="agenda"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Facilidade na formalização da burocracia.",
        "Modelos de documento personalizáveis.",
      ]}
    />
  );
}

SellAuthorization.defaultProps = {
  title: "Autorização de Venda",
  text: `Ao captar um novo imóvel, o Tecimob gera um documento de autorização de Venda/Locação pra você imprimir.`,
};

SellAuthorization.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default SellAuthorization;
