import styled from "styled-components";

export const Popover = styled.div`
  position: absolute;
  bottom: calc(100% + 15px);
  left: 0;
  width: 258px;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 15px;
  text-align: center;

  // Transition
  opacity: 0;
  visibility: hidden;
  translate: 0 -15px;
  transition: all 0.2s ease-in-out;
  will-change: visibility, translate, opacity;

  img {
    width: 100%;
    max-width: 213px;
    margin-top: 20px;
  }
`;

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  :hover ${Popover} {
    visibility: visible;
    opacity: 1;
    translate: 0;
  }
`;

export const Title = styled.span`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: ${(p) => p.theme.colors.secondary};
`;

export const Text = styled.span`
  display: block;
  font-size: 14px;
  text-align: left;
  color: ${(p) => p.theme.colors.secondary};
`;
