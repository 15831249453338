import React from "react";
import { Wrapper, Popover, Title, Text } from "./styles";
import { useQrcode } from "hooks/useQrcode";

function Poplink({ link, children }) {
  const imageUrl = useQrcode(link);

  return (
    <Wrapper>
      <Popover ariaHidden={true}>
        <Title>Escaneie o QR Code para baixar</Title>
        <Text>Disponível na App Store e Google Play</Text>
        <img src={imageUrl} alt="Aplicativo" />
      </Popover>
      {children}
    </Wrapper>
  );
}

export default Poplink;
