import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function IntegrationPortals({ title, text }) {
  return (
    <MainSection
      reversed={false}
      title={title}
      text={text}
      image={<Image src="/inicio/cartao-virtual.svg" alt={title} />}
      items={[
        "Compartilhe seu cartão virtual com clientes e parceiros.",
        "Facilidade de acesso a seu WhatsApp e redes sociais.",
      ]}
    />
  );
}

IntegrationPortals.defaultProps = {
  title: "Cartão Virtual",
  text:
    "Gerador de cartão virtual com todos os seus dados e links importantes, como WhatsApp, redes sociais e seu site, e envie ele para seus clientes e parceiros.",
};

IntegrationPortals.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default IntegrationPortals;
