import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function Image({ alt }) {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: { eq: "sections/inicio/aplicativo.png" }
      ) {
        ...mobileImage
      }
      desktopImage: file(
        relativePath: { eq: "sections/inicio/aplicativo.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 485, quality: 80) {
            ...GatsbyImageSharpFluid_withWebp
            presentationWidth
          }
        }
      }
    }
  `);

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`
    }
  ];

  return <Img alt={alt} fluid={sources} style={{ maxWidth: "485px" }} />;
}

export default Image;
