import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function Reports({ title, text }) {
  return (
    <MainSection
      id="relatorios"
      reversed={true}
      title={title}
      text={text}
      image={<Image src="/inicio/relatorios.svg" alt={title} />}
      items={[
        "Estoque de imóveis e clientes por corretor.",
        "Captação por tipo/negócio e corretor por período.",
        "Negócios e retorno por canais de aquisição.",
      ]}
    />
  );
}

Reports.defaultProps = {
  title: "Relatórios Gerenciais",
  text: `Saiba os seus resultados em números. Para uma boa gestão de negócio, é fundamental que você tenha fácil acesso a todas as informações importantes da sua imobiliária.`,
};

Reports.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Reports;
