import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function FacebookCatalog({ title, text }) {
  return (
    <MainSection
      id="catalogo-facebook"
      reversed={false}
      title={title}
      text={text}
      image={<Image src="/inicio/integracao-facebook.svg" alt={title} />}
      items={["Anúncios dinâmicos;", "Lojas do Facebook;", "E muito mais."]}
    />
  );
}

FacebookCatalog.defaultProps = {
  title: "Integração com o Facebook.",
  text:
    "Um catálogo é um local em que você armazena informações sobre imóveis que deseja anunciar ou vender no Facebook. Você pode conectar o catálogo a diferentes tipos de anúncios e canais de vendas para promover os seus imóveis, incluindo:",
};

FacebookCatalog.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default FacebookCatalog;
