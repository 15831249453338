import React, { useState } from "react";
import PropTypes from "prop-types";
// Styles
import { Wrapper } from "./styles";
import VideoModal from "../VideoModal/index";

// https://developers.google.com/youtube/player_parameters?hl=pt-br
function YoutubeVideo({ size, src, renderPlaceholder }) {
  // Cria estado para mostrar sim/não o iframe do vídeo
  const [hasVideo, setVideo] = useState(false);

  return (
    <>
      <Wrapper size={size} onClick={() => setVideo(true)}>
        {renderPlaceholder()}
      </Wrapper>
      <VideoModal
        isOpen={hasVideo}
        src={src}
        handleClose={() => {
          setVideo(false);
        }}
      />
    </>
  );
}

YoutubeVideo.defaultProps = {
  src: null,
  size: 9 / 16,
  renderPlaceholder: null
};

YoutubeVideo.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  renderPlaceholder: PropTypes.func
};

export default YoutubeVideo;
