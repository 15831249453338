import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

export default function CompareProperties({
  title = "Compare imóveis",
  text = `Compare facilmente em uma tabela as principais caracteristicas entre os imóveis de um atendimento.`,
}) {
  return (
    <MainSection
      reversed={false}
      title={title}
      text={text}
      image={<Image src="/inicio/compare-imoveis.svg" alt={title} />}
      items={[
        "Facilidade em demostrar conhecimento sobre os imóveis.",
        "Ajude o cliente e escolher o que realmente é melhor pra ele.",
      ]}
    />
  );
}
