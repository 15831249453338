import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

export default function NewPropertyNewChance({
  title = "Radar de clientes",
  text = `Perdendo oportunidades? Criamos automaticamente perfis quando os clientes entram em contato ou você compartilha imóveis, assim, a cada novo imóvel cadastrado, será lhe apresentado uma lista de clientes compatíveis, gerando uma nova oportunidade de negócio.`,
}) {
  return (
    <MainSection
      reversed
      title={title}
      text={text}
      image={<Image src="/inicio/novo-imovel-nova-chance.svg" alt={title} />}
      items={[
        "Aumente suas chances abordando sua carteira de clientes.",
        "Mostre a seu cliente que você não esquece dele.",
        "Aborde seu cliente só com o que faz sentido pra ele.",
      ]}
    />
  );
}
