import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function Schedule({ title, text }) {
  return (
    <MainSection
      reversed
      id="agenda"
      title={title}
      text={text}
      image={<Image src="/inicio/agenda.svg" alt={title} />}
      items={[
        "Não perca nenhum compromisso.",
        "Integrado ao Google Agenda.",
        "Receba alertas no seu celular.",
      ]}
    />
  );
}

Schedule.defaultProps = {
  title: "Agenda",
  text: `A ferramenta certa para melhorar sua produtividade e organização. Confiar na memória mesmo que ela seja ótima, pode fazer você deixar passar encontros importantes.`,
};

Schedule.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Schedule;
