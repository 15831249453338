import React from "react";
import PropTypes from "prop-types";
// Components
//import MainSection from "components/MainSection";
//import Image from "./Image";
import Image from "components/Image";
import Button from "components/Button";
import { MainSection } from "./styles";

function Hotsite({ title, text }) {
  return (
    <MainSection
      reversed={true}
      title={title}
      text={text}
      image={<Image src="/inicio/hotsite.png" alt={title} />}
      items={[
        "Valorize os detalhes dos imóveis com imagens, vídeos, plantas, etc.",
        "Agrupe todos os imóveis de um empreendimento em um só lugar.",
        "Acessível por todos os dispositivos, como celular e computador.",
      ]}
    >
      <Button
        as="a"
        href="https://sites.tecimob.com.br/exemplo-hotsite"
        target="_blank"
        rel="noopener noreferrer"
        color="tertiary"
        kind="solid"
        style={{ marginBottom: "15px" }}
      >
        Ver exemplo de Hotsite
      </Button>
    </MainSection>
  );
}

Hotsite.defaultProps = {
  title: "Crie Hotsites",
  text:
    "Hotsites são páginas exclusivas para os melhores imóveis ou empreendimentos dos seus clientes.",
};

Hotsite.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Hotsite;
