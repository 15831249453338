import React, { useState } from "react";
// Components
import MainTitle from "components/MainTitle";
// Styles
import { Container } from "components/Layout/styles";
import {
  Wrapper,
  Doubts,
  Item,
  Title,
  Text,
} from "sections/inicio/FrequentlyQuestions/styles";
import {
  QUESTIONS,
  FAQ_QUESTIONS,
} from "sections/inicio/FrequentlyQuestions/data";
import Accordion from "components/Accordion";

function FrequentlyQuestions() {
  const [isOpen, setIsOpen] = useState(0);

  return (
    <Wrapper>
      <Container size="800px">
        <MainTitle title="Dúvidas Frequentes" />

        {FAQ_QUESTIONS.map((question, index) => (
          <Accordion
            title={question.name}
            isOpen={isOpen === index}
            setExpanded={() => {
              if (index === isOpen) {
                return setIsOpen(-1);
              }
              setIsOpen(index);
            }}
            content={question.answer}
          />
        ))}
      </Container>
    </Wrapper>
  );
}

export default FrequentlyQuestions;
