import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function VisionRealestate({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="agenda"
      title={title}
      text={text}
      image={<Image src="/inicio/visao-da-equipe.svg" alt={title} />}
      items={[
        "Acompanhe seu negócio em poucos cliques.",
        "Tenha informações importantes sempre em mãos."
      ]}
    />
  );
}

VisionRealestate.defaultProps = {
  title: "Visão da equipe",
  text: `Conheça os principais numeros da sua equipe sem precisar quebrar a cabeça. Veja quantos imóveis possuem na carteira, quantos clientes ainda não foram atendidos, entre outros.`
};

VisionRealestate.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node
};

export default VisionRealestate;
