import styled from "styled-components";
import Image from "components/Image";
import shape from "./shape.svg";
import dots from "./dots.svg";

const WIDTH = 957;
const HEIGHT = 543;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${WIDTH}px;
  margin: 60px auto 12%;
`;

export const Inner = styled.div`
  position: relative;
  padding-bottom: 56.73981191%;
`;

export const DetailRight = styled.div``;

const Device = styled.div`
  overflow: auto;
  border-radius: 9px;
  border: 1px solid #cad3dd;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  @media all and (max-width: 768px) {
    overflow: hidden;
  }

  img {
    width: 100%;
  }
`;

export const Desktop = styled(Device)`
  width: 96.1337513062%;
  height: 100%;
  position: absolute;
  top: 0;
`;

export const Phone = styled(Device)`
  position: absolute;
  width: 19.3514644351%;
  height: 61.6942909761%;
  bottom: 0.5%;
  right: 0;
  overflow: hidden;
`;

export const ImageBackground = styled(Image).attrs(() => ({
  src: shape,
  alt: "Imagem de fundo"
}))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 15%;
  left: -${(230 * 100) / WIDTH}%;
`;

export const ImageDetail = styled(Image).attrs(() => ({
  src: dots,
  alt: "Detalhe pontilhado"
}))`
  position: absolute;
  width: ${(110 * 100) / WIDTH}%;
  height: ${(110 * 100) / HEIGHT}%;
  top: ${(148 * 100) / HEIGHT}%;
  right: -5%;
`;
