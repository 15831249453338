import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

export default function Relationship({
  title = "Relacionar-se",
  text = `Mantenha a comunicação entre imobiliária e cliente ativa. Tenha um site para imobiliária preparado para receber seus contatos.`
}) {
  return (
    <MainSection
      title={title}
      text={text}
      image={<Image src="/inicio/relacionar.svg" alt={title} />}
      items={[
        "Site para imobiliária conectado ao WhatsApp;",
        "Formulários de contatos bem posicionados;"
      ]}
    />
  );
}
