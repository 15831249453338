import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: ${({ size }) => `${size * 100}%`};
  position: relative;

  img {
    cursor: pointer;
  }

  iframe,
  img,
  .gatsby-image-wrapper {
    position: absolute !important;
    width: 100%;
    height: 100%;
    border: 0 none;
    top: 0;
    left: 0;
  }
`;
