import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function Image({ alt }) {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "sections/inicio/whatsapp.png" }) {
        ...mobileImage
      }
      desktopImage: file(relativePath: { eq: "sections/inicio/whatsapp.png" }) {
        ...desktopImage
      }
    }
  `);

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return <Img fluid={sources} alt={alt} style={{ maxWidth: "421px" }} />;
}

export default Image;
