import React from "react";
// Components
import Seo from "components/Seo";
import Layout from "components/Layout";
import { GroupSections } from "components/MainSection/styles";
import { Section } from "components/Layout/styles";
import MainTitle from "components/MainTitle";
// Sections - Global
import Cta from "sections/global/Cta";
import Testimonials from "sections/global/Testimonials";
// Sections - Inicio
import Relationship from "sections/inicio/Relationship";
import NewPropertyNewChance from "sections/inicio/NewPropertyNewChance";
import Leads from "sections/inicio/Leads";

import MainLanding from "sections/inicio/MainLanding";
import CompareProperties from "sections/inicio/CompareProperties";
import LifeClient from "sections/inicio/LifeClient";
import LifeProperty from "sections/inicio/LifeProperty";
import Funnel from "sections/inicio/Funnel";
import Schedule from "sections/inicio/Schedule";
import Reports from "sections/inicio/Reports";
import VisitItinerary from "sections/inicio/VisitItinerary";
import App from "sections/inicio/App";
import IntegrationPortals from "sections/inicio/IntegrationPortals";
import VirtualCard from "sections/inicio/VirtualCard";
import VisionRealestate from "sections/inicio/VisionRealestate";
import PreviewSite from "sections/inicio/PreviewSite";
import Hotsite from "sections/inicio/Hotsite";
import SellAuthorization from "sections/inicio/SellAuthorization";
import VisitForm from "sections/inicio/VisitForm";
import CtaSchedule from "sections/global/CtaSchedule";
import FrequentlyQuestions from "sections/inicio/FrequentlyQuestions";
import FacebookCatalog from "sections/inicio/FacebookCatalog";
import Whatsapp from "sections/inicio/Whatsapp";
import SeoCity from "sections/inicio/SeoCity";
import Nps from "sections/inicio/Nps";
import Metrics from "sections/inicio/Metrics";
import Integrations from "sections/inicio/Integrations";
import OruloDwv from "sections/inicio/OruloDwv";
import ChatGpt from "sections/inicio/ChatGpt";
import {
  FAQ_QUESTIONS,
  QUESTIONS,
} from "sections/inicio/FrequentlyQuestions/data";
import RecurringEmail from "sections/inicio/RecurringEmail";
//import ChromeExtension from "sections/inicio/ChromeExtension";

export default function Inicio(props) {
  return (
    <Layout headerColor="#F1F5FC" {...props}>
      <Seo
        titleTemplate={`%s`}
        title="Tecimob - Site e sistema para imobiliária e corretor de imóveis."
        description={`Site, CRM e Aplicativo para imobiliária e corretor de imóveis`}
        ldJson={[
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: FAQ_QUESTIONS.map(({ name, answer }) => ({
              "@type": "Question",
              name: name,
              acceptedAnswer: {
                "@type": "Answer",
                text: answer,
              },
            })),
          },
        ]}
      />
      <MainLanding />
      <Section>
        <MainTitle
          title="Para vender mais e relacionar-se melhor"
          text="Com Tecimob você terá um site rápido, fácil de usar, completo, tanto no computador quanto no celular."
        />
        <PreviewSite />
        <GroupSections>
          <Hotsite />
          <App />
          <LifeProperty />
          <LifeClient />
          <NewPropertyNewChance />
          <Whatsapp />
          <ChatGpt />
        </GroupSections>
      </Section>
      <Section color="#E6F3FE">
        <MainTitle title="Para agilizar seu dia a dia" />
        <GroupSections>
          <Relationship />
          <Funnel />
          <CompareProperties />
          <VisitItinerary />
          <FacebookCatalog />
          <Leads />
          <VisionRealestate />
          <Reports />
          <Metrics />
          <Schedule />
          <VirtualCard />
          <IntegrationPortals />
          <OruloDwv />
          <Integrations />
          <SellAuthorization />
          <RecurringEmail />
          <VisitForm />
          <SeoCity />
          <Nps />
          {/*<ChromeExtension />*/}
          <FrequentlyQuestions />
          <CtaSchedule />
        </GroupSections>
      </Section>
      <Testimonials />
      <Cta />
    </Layout>
  );
}
