import React, { useRef } from "react";
import { Header, Section } from "./styles";
import { MdAddCircleOutline, MdRemoveCircleOutline } from "react-icons/md";

function Accordion({ title, content, i, isOpen, setExpanded }) {
  const header = useRef();
  const contentRef = useRef();

  const onClick = () => {
    setExpanded(!isOpen);
  };

  return (
    <>
      <Header ref={header} isOpen={isOpen} initial={false} onClick={onClick}>
        <h3>{title}</h3>{" "}
        {isOpen ? <MdRemoveCircleOutline /> : <MdAddCircleOutline />}
      </Header>
      <Section
        ref={contentRef}
        isOpen={isOpen}
        aria-hidden={isOpen ? "false" : "true"}
        style={{ height: isOpen ? contentRef?.current?.scrollHeight : 0 }}
      >
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Section>
    </>
  );
}

export default Accordion;
