import styled from "styled-components";
import { Breakpoints } from "../../config-styles";
import { Wrapper as ButtonWrapper } from "components/Button/styles";

export const Embed = styled.div`
  max-width: 1024px;
  max-height: 576px;

  position: relative;
  width: 100%;
  flex-grow: 1;
  margin-top: 60px;
  margin-bottom: 80px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0 none;
    border-radius: 5px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.9);
`;

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${ButtonWrapper} {
    position: absolute;
    left: 50%;
    top: 100%;
    transform: translateX(-50%);
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: ${({ theme }) => theme.colors.secondary};
  opacity: 0.7;
  cursor: pointer;
  margin-bottom: 15px;

  ${Breakpoints.desktop} {
    bottom: 100%;
    left: 100%;
  }

  ${Breakpoints.desktopMax} {
    bottom: 100%;
    left: 50%;

    transform: translateX(-50%);
  }
`;
