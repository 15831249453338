import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function Funnel({ title, text }) {
  return (
    <MainSection
      id="funil-de-vendas"
      reversed
      title={title}
      text={text}
      image={<Image src="/inicio/funil-jornada-de-compra.svg" alt={title} />}
      items={[
        "Visão ampla dos negócios em andamento.",
        "Conduza melhor seus clientes até a compra.",
        "Atenda com excelencia e venda mais."
      ]}
    />
  );
}

Funnel.defaultProps = {
  title: "Jornada de compra",
  text: `Toda venda passa por etapas parecidas, como: primeiro contato, atendimento, contra proposta, visita, e por aí vai. Com o funil de vendas, você pode perceber visualmente em que etapa se encontra cada uma dos clientes que você está negociando e conduzi-los com mais facilidade até a finalização.`
};

Funnel.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node
};

export default Funnel;
