import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

export default function VisitItinerary({
  title = "Roteiro de visitas",
  text = `Crie roteiro com os imóveis que você irá visitar com o cliente e compartilhe através do WhatsApp.`
}) {
  return (
    <MainSection
      reversed={true}
      title={title}
      text={text}
      items={[
        "Defina a melhor rota antes de sair.",
        "Ganhe tempo com seu planejamento."
      ]}
      image={<Image alt={title} />}
    />
  );
}
