import styled from "styled-components";
import MainSectionC from "components/MainSection";
import { ColContent, ColImage } from "components/MainSection/styles";

export const MainSection = styled(MainSectionC)`
  ${ColContent} {
    width: 45%;
  }

  ${ColImage} {
    width: 55%;
  }
`;
