import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function Integrations({ title, text }) {
  return (
    <MainSection
      reversed={true}
      id="metrics"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Otimize a administração financeira, contrato, e processos operacionais com facilidade.",
        "Gestão Eficaz e simplificada.",
        "Economize tempo e recursos.",
      ]}
    />
  );
}

Integrations.defaultProps = {
  title: "Soluções Integradas para Sua Gestão Administrativa",
  text: `Nossa integração com Pleno Imob e Superlógica oferece uma gestão administrativa eficaz e simplificada para a sua imobiliária.`,
};

Integrations.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Integrations;
