import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function LifeClient({ title, text }) {
  return (
    <MainSection
      id="vida-do-cliente"
      reversed={false}
      title={title}
      text={text}
      image={<Image src="/inicio/vida-do-cliente.svg" alt={title} />}
      items={[
        "Ficha cadastral, anotações e documentos.",
        "Histórico de conversas, imóveis e negócios.",
      ]}
    />
  );
}

LifeClient.defaultProps = {
  title: "Vida do Cliente",
  text: `Saiba TUDO sobre o cliente. O que você conversou com ele, quais imóveis você enviou por WhatsApp ou e-mail, entre outros dados. Crie o perfil de imóvel que ele está procurando para ser avisado sempre que cadastrar um novo imóvel compatível com as características desejadas.`,
};
LifeClient.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default LifeClient;
