import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function SeoCity({ title, text }) {
  return (
    <MainSection
      reversed={true}
      id="agenda"
      title={title}
      text={text}
      image={<Image alt={title} />}
      items={[
        "Melhora no SEO do imóvel",
        "Mais agilidade no cadastro do imóvel",
      ]}
    />
  );
}

SeoCity.defaultProps = {
  title: "Potencialize seu SEO",
  text: `Enriqueça o SEO do seu site imobiliário escrevendo informações detalhadas sobre bairros, cidades e condomínios, que serão automaticamente replicadas nos imóveis vinculados a eles, destacando as qualidades dos locais.`,
};

SeoCity.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default SeoCity;
