import React from "react";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";
import ImageGooglePlay from "sections/global/CtaApp/ImageGooglePlay";
import ImageAppStore from "sections/global/CtaApp/ImageAppStore";
import { graphql, useStaticQuery } from "gatsby";
import { CustomSection, List } from "./styles";
import Poplink from "components/Poplink";

export default function App({
  title = "Aplicativo para celular",
  text = `Tenha acesso as informações dos seus imóveis e clientes na palma da sua mão, independente de onde você estiver`,
}) {
  const {
    site: {
      siteMetadata: { googlePlayUrl, appStoreUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          googlePlayUrl
          appStoreUrl
        }
      }
    }
  `);

  return (
    <CustomSection
      reversed={false}
      title={title}
      text={text}
      items={[
        "Cadastre imóveis e clientes de onde estiver.",
        "Receba notificações instântaneas ao receber um contato.",
        "Compartilhe imóveis direto no whatsapp com registro automático no CRM.",
      ]}
      image={<Image alt={title} />}
    >
      <List>
        <li>
          <Poplink link={appStoreUrl}>
            <a
              aria-label="Aplicativo da AppStore"
              href={appStoreUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageAppStore />
            </a>
          </Poplink>
        </li>
        <li>
          <Poplink link={googlePlayUrl}>
            <a
              aria-label="Aplicativo da GooglePlay"
              href={googlePlayUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <ImageGooglePlay />
            </a>
          </Poplink>
        </li>
      </List>
    </CustomSection>
  );
}
