import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "./Image";

function Nps({ title, text }) {
  return (
    <MainSection
      reversed={false}
      id="nps-brasil"
      title={title}
      text={text}
      image={<Image alt={title} />}
    />
  );
}

Nps.defaultProps = {
  title: "Avaliação do Cliente e Alcance Nacional",
  text: `Atendemos milhares de clientes em todo o Brasil. Em pesquisa realizada em 2023 com mais de 3.600 avaliações, 87% dos participantes nos classificaram com notas 7 ou mais, sendo que destes 66% aplicaram nota 9 e 10 destacando nossa dedicação ao serviço de qualidade. Com um NPS sólido e uma presença de destaque, estamos comprometidos em proporcionar soluções excepcionais para nossos clientes.`,
};

Nps.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Nps;
