import React, { useRef } from "react";
import {
  Wrapper,
  Inner,
  Desktop,
  Phone,
  ImageBackground,
  ImageDetail
} from "./styles";
import DesktopImage from "./DesktopImage";
import MobileImage from "./MobileImage";

const PreviewSite = () => {
  const $desktop = useRef(null);
  const $phone = useRef(null);

  return (
    <Wrapper>
      <ImageBackground />
      <ImageDetail />
      <Inner>
        <Desktop
          ref={$desktop}
          onScroll={event => {
            const scrollHeight =
              $desktop.current.scrollHeight - $desktop.current.offsetHeight;
            const percentage = event.target.scrollTop / scrollHeight;

            $phone.current.scrollTo(
              0,
              ($phone.current.scrollHeight - $phone.current.offsetHeight) *
                percentage
            );
          }}
        >
          <DesktopImage alt="Tecimob - site modelo desktop" />
        </Desktop>
        <Phone ref={$phone}>
          <MobileImage alt="Tecimob - site modelo mobile" />
        </Phone>
      </Inner>
    </Wrapper>
  );
};

export default PreviewSite;
