import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function IntegrationPortals({ title, text }) {
  return (
    <MainSection
      reversed={true}
      title={title}
      text={text}
      image={<Image src="/inicio/portais-imobiliarios.svg" alt={title} />}
      items={[
        "Integração automática do seu estoque com os principais portais.",
        "Maior exposição, geração de contatos e negócios.",
        "Gerencie tudo cadastrando e editando tudo por aqui.",
      ]}
    />
  );
}

IntegrationPortals.defaultProps = {
  title: "Integrado a portais",
  text:
    "Os portais imobiliários são grandes parceiros captadores de tráfego e contato qualificado de pessoas que realmente estão a procura de imóveis. Espelhe seus estoque de imóveis gerenciando apenas o Tecimob.",
};

IntegrationPortals.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default IntegrationPortals;
