import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { MdClose } from "react-icons/md";

import { Wrapper, Container, Embed, ButtonClose } from "./styles";
import Signup from "components/Signup";

function VideoModal({ src, isOpen, handleClose }) {
  const iframe = useRef(null);

  useEffect(() => {
    const listener = e => {
      if (e.which === 27) handleClose();
    };

    window.addEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <Wrapper>
      <Container>
        <Embed>
          <ButtonClose onClick={handleClose}>
            <MdClose size={24} />
          </ButtonClose>
          <iframe
            title="Vídeo da modal"
            ref={iframe}
            allowFullScreen=""
            src={`${src}?showinfo=0&color=white&loop=0&controls=1&autoplay=1&&rel=0&modestbranding=1`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
          <Signup color="primary" kind="gradient" onOpen={handleClose}>
            Iniciar teste gratuito
          </Signup>
        </Embed>
      </Container>
    </Wrapper>
  );
}

VideoModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

VideoModal.defaultProps = {
  isOpen: true,
  handleClose: PropTypes.func.isRequired
};

export default VideoModal;
