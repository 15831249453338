import styled from "styled-components";
import { H1, P } from "components/Typograph";
import { Wrapper as ButtonWrapper } from "components/Button/styles";
import { Breakpoints } from "../../../config-styles";
import Button from "components/Button";

export const Wrapper = styled.section`
  width: 100%;
  padding: 90px 50px;
  overflow: hidden;
  background: #f1f5fc;

  @media all and (min-width: 40em) {
    padding: 90px 50px;
  }

  @media all and (max-width: 40em) {
    padding: 20px;
    text-align: center;
  }
`;

export const Container = styled.div`
  display: flex;
  max-width: ${({ theme }) => theme.baseWidth};
  margin: 0 auto;

  @media all and (max-width: 40em) {
    flex-direction: column-reverse;
  }

  small {
    display: block;
    color: #2b343d;
    font-size: 14px;
  }
`;

export const Box = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

export const BoxText = styled(Box)`
  @media all and (min-width: 40em) {
    width: 49%;
    padding-left: 80px;
  }
  @media all and (max-width: 40em) {
    width: 100%;
  }
`;

export const BoxVideo = styled(Box)`
  @media all and (min-width: 40em) {
    width: 51%;
  }
  @media all and (max-width: 40em) {
    margin-bottom: 30px;
    width: 100%;
  }
`;

export const Title = styled(H1)`
  margin-bottom: 0.2em;

  @media all and (max-width: 40em) {
    font-size: 25px;
    margin-bottom: 15px;
  }
`;

export const Text = styled(P)`
  line-height: 30px;

  @media all and (max-width: 40em) {
    font-size: 14px;
    line-height: 30px;
  }
`;

export const Video = styled.video`
  border-radius: 5px;

  @media all and (min-width: 40em) {
    width: calc(100% + 50px);
    margin-right: -50px;
  }

  @media all and (max-width: 40em) {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  display: flex;

  ${Breakpoints.desktop} {
    margin: 20px 0;

    ${ButtonWrapper} + ${ButtonWrapper} {
      margin-left: 15px;
    }
  }

  .hide--mobile {
    ${Breakpoints.tabletMax} {
      display: none;
    }
  }

  ${Breakpoints.desktopMax} {
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }
`;
