import React from "react";
import PropTypes from "prop-types";
// Components
import Image from "./Image";
import { CustomSection } from "./styles";

function Reports({ title, text }) {
  const image = <Image src="/inicio/whatsapp.png" alt={title} />;
  return (
    <CustomSection
      id="relatorios"
      reversed={false}
      title={title}
      text={text}
      image={image}
      items={[
        "Seu CRM dentro do seu WhatsApp Web;",
        "Mensagens prontas para envio;",
        "Compartilhe seus imóveis direto pelo WhatsApp;",
      ]}
    />
  );
}

Reports.defaultProps = {
  title: "Plugin de atendimento do WhatsApp",
  text: `Tenha as principais funções do seu CRM diretamente no seu WhatsApp Web. Atenda com mais qualidade e produtividade, podendo conhecer o seu cliente em um clique, compartilhar imóveis, conhecer o histórico e também seu perfil.`,
};

Reports.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default Reports;
