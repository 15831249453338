import React from "react";
import PropTypes from "prop-types";
// Components
import MainSection from "components/MainSection";
import Image from "components/Image";

function LifeProperty({ title, text }) {
  return (
    <MainSection
      id="vida-do-imovel"
      reversed={true}
      title={title}
      text={text}
      image={<Image src="/inicio/vida-do-imovel.svg" alt={title} />}
      items={[
        "Galeria de fotos pública e privada.",
        "Histórico de clientes e ofertas.",
        "Suporte a vídeo e visita 360º.",
      ]}
    />
  );
}

LifeProperty.defaultProps = {
  title: "Vida do Imóvel",
  text: `Tenha acesso rápido a tudo o que aconteceu com o imóvel, como por exemplo: quando foi captado, alterações de valor, número de visitas no site, clientes para quem foi enviado/anexado, entre outros.`,
};

LifeProperty.propTypes = {
  title: PropTypes.node,
  text: PropTypes.node,
};

export default LifeProperty;
