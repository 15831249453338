import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function MainLading() {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(
        relativePath: { eq: "sections/inicio/sibele-capa-site.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 550, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      desktopImage: file(
        relativePath: { eq: "sections/inicio/sibele-capa-site.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 966, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  // Set up the array of image data and `media` keys.
  // You can have as many entries as you'd like.
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return <Img fluid={sources} alt="Cover Sibele" />;
}
